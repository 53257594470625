
import { Component, Vue } from 'vue-property-decorator'
import { ExperienceData } from '../../types/experienceApply'

@Component({
  name: 'experienceDetail'
})
export default class extends Vue {
  private info: ExperienceData = {
    productId: '',
    // 序号
    orderNum: '',
    // 产品类型 1体验 2专业
    type: '',
    // 标签ID
    labelIds: '',
    // 详细地址
    url: '',
    // 产品名称
    productName: '',
    // 产品描述
    productDescribe: ''
  }

  get productId () {
    return this.$route.params.id || ''
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.experienceApply.selectDetailByProductId, {
      productId: this.productId
    }).then(res => {
      this.info = res
    })
  }
}
